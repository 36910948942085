@use '~admin-lte/build/scss/adminlte.scss';


.btn-circle {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}


// Fix for the sidebar
// body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) {

//   .content-wrapper,
//   .main-footer,
//   .main-header {
//     @include transition(margin-left $transition-speed $transition-fn);

//     margin-left: $sidebar-width;

//     .sidebar-collapse & {
//       margin-left: 0;
//     }

//     @include media-breakpoint-down(sm) {
//       margin-left: 0;
//     }
//   }
// }