.collections-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .collection-item {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    display: block;
    margin: 16px 0;
    padding: 16px;
  }
  
  .collection-item h2 {
    font-size: 1.5rem;
    margin: 0;
  }
  
  .collection-item p {
    color: #666;
    font-size: 1rem;
    margin: 8px 0 0;
  }
  
  .collection-item a {
    color: #333;
    text-decoration: none;
  }

  .collection-item a:hover {
    color: #555;
  }