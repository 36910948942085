form.collection-batch-import {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.collection-batch-import label {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-bottom: 8px;
}

.collection-batch-import input[type='text'] {
    font-size: 16px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    width: 300px;
}

.collection-batch-import button[type='button'] {
    font-size: 16px;
    padding: 8px;
    border-radius: 4px;
    border: none;
    background-color: #3f51b5;
    color: white;
    cursor: pointer;
    width: 300px;
}